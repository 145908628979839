import { default as activateVCAUaFz3S3Meta } from "/home/ploi/kayotravel.ptrdigital.dev/pages/auth/activate.vue?macro=true";
import { default as conditionsmVXEZktRqrMeta } from "/home/ploi/kayotravel.ptrdigital.dev/pages/auth/conditions.vue?macro=true";
import { default as forgot_45passwordMstHTeQv49Meta } from "/home/ploi/kayotravel.ptrdigital.dev/pages/auth/forgot-password.vue?macro=true";
import { default as loginQTRonYSc8AMeta } from "/home/ploi/kayotravel.ptrdigital.dev/pages/auth/login.vue?macro=true";
import { default as registersQUOhvhwAuMeta } from "/home/ploi/kayotravel.ptrdigital.dev/pages/auth/register.vue?macro=true";
import { default as regulationsmLrylfzW6yMeta } from "/home/ploi/kayotravel.ptrdigital.dev/pages/auth/regulations.vue?macro=true";
import { default as reset_45passwordrg4ALjsoM1Meta } from "/home/ploi/kayotravel.ptrdigital.dev/pages/auth/reset-password.vue?macro=true";
import { default as indexBtrmSsGMbLMeta } from "/home/ploi/kayotravel.ptrdigital.dev/pages/index.vue?macro=true";
import { default as _91id_93lO2nDiOkn6Meta } from "/home/ploi/kayotravel.ptrdigital.dev/pages/trip/[id].vue?macro=true";
import { default as edit_45passwordrcKqG1GWSiMeta } from "/home/ploi/kayotravel.ptrdigital.dev/pages/user/edit-password.vue?macro=true";
import { default as editzwdeoRkseiMeta } from "/home/ploi/kayotravel.ptrdigital.dev/pages/user/edit.vue?macro=true";
export default [
  {
    name: activateVCAUaFz3S3Meta?.name ?? "auth-activate",
    path: activateVCAUaFz3S3Meta?.path ?? "/auth/activate",
    meta: activateVCAUaFz3S3Meta || {},
    alias: activateVCAUaFz3S3Meta?.alias || [],
    redirect: activateVCAUaFz3S3Meta?.redirect,
    component: () => import("/home/ploi/kayotravel.ptrdigital.dev/pages/auth/activate.vue").then(m => m.default || m)
  },
  {
    name: conditionsmVXEZktRqrMeta?.name ?? "auth-conditions",
    path: conditionsmVXEZktRqrMeta?.path ?? "/auth/conditions",
    meta: conditionsmVXEZktRqrMeta || {},
    alias: conditionsmVXEZktRqrMeta?.alias || [],
    redirect: conditionsmVXEZktRqrMeta?.redirect,
    component: () => import("/home/ploi/kayotravel.ptrdigital.dev/pages/auth/conditions.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordMstHTeQv49Meta?.name ?? "auth-forgot-password",
    path: forgot_45passwordMstHTeQv49Meta?.path ?? "/auth/forgot-password",
    meta: forgot_45passwordMstHTeQv49Meta || {},
    alias: forgot_45passwordMstHTeQv49Meta?.alias || [],
    redirect: forgot_45passwordMstHTeQv49Meta?.redirect,
    component: () => import("/home/ploi/kayotravel.ptrdigital.dev/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: loginQTRonYSc8AMeta?.name ?? "auth-login",
    path: loginQTRonYSc8AMeta?.path ?? "/auth/login",
    meta: loginQTRonYSc8AMeta || {},
    alias: loginQTRonYSc8AMeta?.alias || [],
    redirect: loginQTRonYSc8AMeta?.redirect,
    component: () => import("/home/ploi/kayotravel.ptrdigital.dev/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: registersQUOhvhwAuMeta?.name ?? "auth-register",
    path: registersQUOhvhwAuMeta?.path ?? "/auth/register",
    meta: registersQUOhvhwAuMeta || {},
    alias: registersQUOhvhwAuMeta?.alias || [],
    redirect: registersQUOhvhwAuMeta?.redirect,
    component: () => import("/home/ploi/kayotravel.ptrdigital.dev/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: regulationsmLrylfzW6yMeta?.name ?? "auth-regulations",
    path: regulationsmLrylfzW6yMeta?.path ?? "/auth/regulations",
    meta: regulationsmLrylfzW6yMeta || {},
    alias: regulationsmLrylfzW6yMeta?.alias || [],
    redirect: regulationsmLrylfzW6yMeta?.redirect,
    component: () => import("/home/ploi/kayotravel.ptrdigital.dev/pages/auth/regulations.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordrg4ALjsoM1Meta?.name ?? "auth-reset-password",
    path: reset_45passwordrg4ALjsoM1Meta?.path ?? "/auth/reset-password",
    meta: reset_45passwordrg4ALjsoM1Meta || {},
    alias: reset_45passwordrg4ALjsoM1Meta?.alias || [],
    redirect: reset_45passwordrg4ALjsoM1Meta?.redirect,
    component: () => import("/home/ploi/kayotravel.ptrdigital.dev/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: indexBtrmSsGMbLMeta?.name ?? "index",
    path: indexBtrmSsGMbLMeta?.path ?? "/",
    meta: indexBtrmSsGMbLMeta || {},
    alias: indexBtrmSsGMbLMeta?.alias || [],
    redirect: indexBtrmSsGMbLMeta?.redirect,
    component: () => import("/home/ploi/kayotravel.ptrdigital.dev/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93lO2nDiOkn6Meta?.name ?? "trip-id",
    path: _91id_93lO2nDiOkn6Meta?.path ?? "/trip/:id()",
    meta: _91id_93lO2nDiOkn6Meta || {},
    alias: _91id_93lO2nDiOkn6Meta?.alias || [],
    redirect: _91id_93lO2nDiOkn6Meta?.redirect,
    component: () => import("/home/ploi/kayotravel.ptrdigital.dev/pages/trip/[id].vue").then(m => m.default || m)
  },
  {
    name: edit_45passwordrcKqG1GWSiMeta?.name ?? "user-edit-password",
    path: edit_45passwordrcKqG1GWSiMeta?.path ?? "/user/edit-password",
    meta: edit_45passwordrcKqG1GWSiMeta || {},
    alias: edit_45passwordrcKqG1GWSiMeta?.alias || [],
    redirect: edit_45passwordrcKqG1GWSiMeta?.redirect,
    component: () => import("/home/ploi/kayotravel.ptrdigital.dev/pages/user/edit-password.vue").then(m => m.default || m)
  },
  {
    name: editzwdeoRkseiMeta?.name ?? "user-edit",
    path: editzwdeoRkseiMeta?.path ?? "/user/edit",
    meta: editzwdeoRkseiMeta || {},
    alias: editzwdeoRkseiMeta?.alias || [],
    redirect: editzwdeoRkseiMeta?.redirect,
    component: () => import("/home/ploi/kayotravel.ptrdigital.dev/pages/user/edit.vue").then(m => m.default || m)
  }
]